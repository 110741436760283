<!-- 文件描述: 采集看板详情 -->
<template>
  <div class="acquisition-board-details">
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">任务时间：</p>
          <el-date-picker
            v-model="searchData"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeDate"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" stripe>
        <el-table-column prop="taskName" label="任务名称" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="activeName" label="采集器" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="startTime" label="开始时间" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="endTime" label="结束时间" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="allCount" label="url数量" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.parseCount + " / " + scope.row.allCount }}
          </template>
        </el-table-column>
        <el-table-column prop="nestCount" label="下级url量级" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="changeSize"
          @current-change="changeCurrent"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetails } from "@/http/api/acquisitionBoard.js"
export default {
  name: "acquisitionBoardDetails",
  data() {
    return {
      activeName: "",//采集器名称
      taskName: "",//任务名称
      searchData:[],//搜索时间
      tableData: [],//列表数据
      currentPage: 1,//当前页
      pageSize: 10,//每页显示条数
      total: 0,//总条数
    };
  },
  mounted() {
    //获取任务名称
    this.taskName = this.$route.params.taskName;
    this.activeName = this.$route.params.activeName;
    //获取列表数据
    this.getData();
  },
  methods: {
    //获取列表数据
    async getData() {
      let { body } = await getDetails({ pages: this.currentPage, rows: this.pageSize, taskName: this.taskName, startTime: this.searchData[0]?this.searchData[0]:"", endTime: this.searchData[1]?this.searchData[1]:"" });
      if(body){
        body.content.forEach(item => {
          item.activeName = this.activeName;
        });
        this.tableData = body.content;
        this.total = body.total;
      }else{
        this.tableData = [];
        this.total = 0;
      }
    },
    //搜索时间
    changeDate() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    //当前页
    changeCurrent(val) {
      this.currentPage = val;
      this.getData();
    },
    //每页显示条数
    changeSize(val) {
      this.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.acquisition-board-details {
  padding: 30px 24px;
}
.active {
  display: flex;
  margin-bottom: 30px;
}
.active-info {
  display: flex;
  align-items: center;
  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
    }
  }
}
//分页样式
.pagination{
  margin-top: 20px;
  text-align: right;
}
//表格样式
::v-deep .el-table th.el-table__cell {
  background: #EBF0FF;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F7FAFF;
}
::v-deep .el-table td.el-table__cell {
  border: none;
}
</style>